var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "personal inner-wrapper"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.displayed.loading,
      expression: "!displayed.loading"
    }],
    staticClass: "accounts-form"
  }, [_c('div', {
    staticClass: "d-flex df-row"
  }, [_c('h1', {
    staticClass: "accounts-form__small-bi-header cursor",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/introduction/main');
      }
    }
  }, [_vm._v(" Qpick ")]), _c('div', {
    staticClass: "spacer"
  }), _c('button', {
    staticClass: "close-btn",
    on: {
      "click": _vm.closeWindow
    }
  })]), _c('h1', {
    staticClass: "personal__header accounts-form__header"
  }, [_vm._v("계정 정보")]), _c('div', {
    staticClass: "accounts-form__textbox"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("아이디")]), _c('div', {
    staticClass: "id"
  }, [_vm._v(_vm._s(_vm.email))])]), _c('outlinedTextField', {
    ref: "nameField",
    staticClass: "accounts-form__outlined-text",
    attrs: {
      "placeholder": "별명",
      "type": "text",
      "maxlength": "15"
    },
    on: {
      "value": _vm.setName
    }
  }), _c('div', {
    staticClass: "accounts-form__toggle"
  }, [_c('label', [_vm._v("마케팅 정보 알림 수신 동의")]), _c('Toggle', {
    staticClass: "accounts-form__toggle",
    model: {
      value: _vm.advAgree,
      callback: function callback($$v) {
        _vm.advAgree = $$v;
      },
      expression: "advAgree"
    }
  })], 1), _vm.error.name ? _c('p', {
    staticClass: "accounts-form__form-error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e(), _c('button', {
    staticClass: "accounts-form__submit",
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" 정보 수정하기 ")]), _c('button', {
    staticClass: "accounts-footer__button-transparent",
    on: {
      "click": _vm.goPasswordChange
    }
  }, [_vm._v(" 비밀번호 변경하기 ")]), _c('div', {
    staticClass: "personal__withdrawal"
  }, [_c('a', {
    staticClass: "personal__withdrawal--link cursor",
    on: {
      "click": _vm.goWithdrawal
    }
  }, [_vm._v("회원 탈퇴하기")])])], 1)]), _c('toastMsg', {
    ref: "toast"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }