<template>
  <div class="wrapper">
    <div class="personal inner-wrapper">
      <div class="accounts-form" v-show="!displayed.loading">
        <div class="d-flex df-row">
          <h1
            class="accounts-form__small-bi-header cursor"
            @click="$router.push('/introduction/main')"
          >
            Qpick
          </h1>
          <div class="spacer"></div>
          <button class="close-btn" @click="closeWindow"></button>
        </div>
        <h1 class="personal__header accounts-form__header">계정 정보</h1>
        <div class="accounts-form__textbox">
          <div class="label">아이디</div>
          <div class="id">{{ email }}</div>
        </div>
        <outlinedTextField
          placeholder="별명"
          @value="setName"
          class="accounts-form__outlined-text"
          type="text"
          maxlength="15"
          ref="nameField"
        ></outlinedTextField>
        <div class="accounts-form__toggle">
          <label>마케팅 정보 알림 수신 동의</label>
          <Toggle v-model="advAgree" class="accounts-form__toggle"></Toggle>
        </div>
        <p class="accounts-form__form-error" v-if="error.name">
          {{ error.name }}
        </p>
        <button class="accounts-form__submit" @click="submit">
          정보 수정하기
        </button>
        <button
          class="accounts-footer__button-transparent"
          @click="goPasswordChange"
        >
          비밀번호 변경하기
        </button>
        <div class="personal__withdrawal">
          <a class="personal__withdrawal--link cursor" @click="goWithdrawal"
            >회원 탈퇴하기</a
          >
        </div>
      </div>
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import outlinedTextField from "@/components/accounts/outlined-text-field.vue";
import toastMsg from "@/components/accounts/toast-message.vue";
import Toggle from "@vueform/toggle/dist/toggle.vue2.js";
import authAPI from "@/api/accounts/auth.js";
import userAPI from "@/api/accounts/user.js";
import "@/assets/scss/accounts/basis.scss";
import "@vueform/toggle/themes/default.css";
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

Vue.use(VueCompositionAPI);

export default {
  data() {
    return {
      user_id: null,
      email: "",
      name: "",
      advAgree: false,
      error: {name: ""},
      displayed: {loading: true},
      callback: ""
    };
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoggedData"])
  },
  methods: {
    setName(v) {
      this.name = v;
    },
    submit() {
      userAPI.update(this.user_id, null, null, this.name, null).then(res => {
        if (res.status == 201) {
          userAPI.setAdvAgree(this.user_id, this.advAgree).then(res2 => {
            if (res2.status == 201) {
              if (this.name !== this.getLoggedData.privacy?.name) {
                this.$store.dispatch("ModuleUser/actUserLog", {
                  eventName: "이름 변경",
                  requestLocation: "큐픽 > 계정정보"
                });
              }
              this.$refs.toast.show("계정정보가 수정되었습니다.");
            } else {
              this.$refs.toast.show(res2.data.errors[0].userMsg);
            }
          });
        } else {
          this.$refs.toast.show(res.data.errors[0].userMsg);
        }
      });
    },
    goPasswordChange() {
      !this.callback
        ? this.$router.push("/accounts/password-change")
        : this.$router.push(
            "/accounts/password-change?callback=" + this.callback
          );
    },
    goWithdrawal() {
      if (this.getLoggedData.manager) {
        this.setAlert({
          isVisible: true,
          message: `관리자 권한해제 후 탈퇴신청 가능합니다.`
        });
        return;
      }
      if (this.getLoggedData.partner) {
        this.setAlert({
          isVisible: true,
          message: `파트너센터 탈퇴 후 탈퇴신청 가능합니다.`
        });
        return;
      }
      !this.callback
        ? this.$router.push("/accounts/withdrawal")
        : this.$router.push("/accounts/withdrawal?callback=" + this.callback);
    },
    closeWindow() {
      this.$router.go(-1) || window.close();
    },
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  created() {
    //콜백 파라미터 여부
    if (this.$route.query.callback) {
      this.callback = this.$route.query.callback;
    }

    this.$EventBus.$on("alertCancel", event => {
      switch (event) {
        case "back":
          this.$router.go(-1);
          break;

        case "login":
          this.$router.push("/accounts/login");
          break;

        case "not_allowed":
          this.$router.push("/admin/dashboard");
          break;
      }
    });
  },
  mounted() {
    authAPI.logged().then(res => {
      if (res.status == 200) {
        if (res.data.grade < 1) {
          this.setAlert({
            isVisible: true,
            message: "접근 권한이 없습니다.",
            event: "not_allowed"
          });
        } else {
          this.user_id = res.data.id;
          this.advAgree = res.data.advAgree ? res.data.advAgree.agree : false;
          this.displayed.loading = false;
          this.name = res.data.privacy.name;
          this.email = res.data.privacy.email;
          this.$refs.nameField.set(res.data.privacy.name);
        }
      }
    });
  },
  components: {
    Toggle,
    outlinedTextField,
    toastMsg
  }
};
</script>

<style>
.personal__header {
  margin-bottom: 100px;
}
.accounts-form__toggle {
  color: #2c2c2c;
  font-size: 16px;
  margin-bottom: 24px;
}
.accounts-form__toggle > div {
  float: right;
}
.accounts-form__submit {
  margin-bottom: 16px;
}
.accounts-form__textbox {
  height: 56px;
  background: #f7f9fc;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 20px 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
}
.accounts-form__textbox .label {
  margin-right: 52px;
  color: #606060;
}
.personal__withdrawal {
  margin: 16px auto;
  text-align: center;
}
.personal__withdrawal--link {
  color: #8d8d8d;
  font-size: 14px;
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .close-btn {
    width: fit-content;
    height: fit-content;
    padding-top: 27px;
    font-size: 0;
  }
  .close-btn::after {
    content: "×";
    font-size: 24px;
  }
}
</style>
